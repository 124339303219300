@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .header-Text-Night-Mode {
    @apply text-white;
  }

  .text-Soft-Night-Mode {
    @apply text-white/70;
    /* @apply text-yellow-400; */
  }

  .border-Color-Night-Mode {
    @apply border-[#343538];
  }

  .card-Border-Color-Shadow-xl-Night-Mode {
    @apply border-white/50 shadow-xl;
  }
}
