#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 100vw;
}

iper-slide:nth-child(2n) {
  width: 100%;
}

.swiper-slide:nth-child(3n) {
  width: 20%;
}

/*  */
.review-Slide {
  background: transparent;
  @apply border-t-2 border-white;
}
