@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .header-Text-Light-Mode {
    @apply text-[#282c34];
  }

  .text-Soft-Light-Mode {
    @apply text-[#303030];
  }

  .border-Color-Light-Mode {
    @apply border-black/10;
  }
  .card-Border-Color-Shadow-xl-Light-Mode {
    @apply border-black/10 shadow-xl;
  }
}
