@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .matrix-Box-Shadow {
    box-shadow: rgba(0, 0, 0, 0.7) 0px 4px 6px -2px,
      rgba(0, 0, 0, 0.5) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.3) 0px -3px 0px inset;
  }
}
