@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 470px) {
  .text-5xl-Bellow470 {
    @apply text-5xl;
  }
}
@media (max-width: 470px) {
  .text-5xl-Bellow470 {
    @apply text-5xl;
  }
  .text-4xl-Bellow470 {
    @apply text-4xl;
  }
  .text-xl-Bellow470 {
    @apply text-xl;
  }
}

@media (max-width: 400px) {
  .text-3xl-Bellow400 {
    @apply text-3xl;
  }
  .text-lg-Bellow400 {
    @apply text-base;
  }
}

@media (max-width: 390px) {
  .text-sm-Bellow390 {
    @apply text-sm;
  }
}
@media (max-width: 370px) {
  .text-3xl-Bellow370 {
    @apply text-3xl;
  }
}

@media (max-width: 310px) {
  .text-sm-and-bold-Bellow310 {
    @apply text-xs font-bold;
  }
}

@media (max-width: 250px) {
  .basis-full-Bellow-250 {
    @apply basis-full;
  }
}
