@import url("/src/Css/Media.css");
@import url("/src/Css/ReviewSlider.css");
@import url("/src/Css/Scrollbar.css");
@import url("/src/Css/LightMode.css");
@import url("/src/Css/NightMode.css");
@import url("/src/Css/Utility.css");
@import url("/src/Css/Navbar.css");
@import url("/src/Css/Matrix.css");

* {
  padding: 0;
  margin: 0;
}
body {
  /* @apply text-white; */
  @apply font-mono;
  position: relative;
}
