/*  */
.our-Services-Nav-Control-Container::-webkit-scrollbar {
  /* display: none; */

  @apply h-2;
  @apply hidden;
  @apply group-hover/Scrollbar:block;
}
.our-Services-Nav-Control-Container::-webkit-scrollbar-track {
  @apply group-hover/Scrollbar:bg-[#242424];
}
.our-Services-Nav-Control-Container::-webkit-scrollbar-track {
  @apply border-l border-white;
}
.our-Services-Nav-Control-Container::-webkit-scrollbar-thumb {
  @apply bg-[#39b136];
  @apply rounded-xl;
  @apply invisible;
  @apply group-hover/Scrollbar:visible;
}
