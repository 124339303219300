@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrolled {
    color: #212529;
    background: linear-gradient(rgba(0, 0, 0, 0.964), rgba(0, 0, 0, 0.805));
    z-index: 100;
    box-shadow: 0px 4px 6px -2px #e27201;
    /* box-shadow: #e27201 0px 2px 4px, #e271014d 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    @apply py-[2px];
  }

  .navBar-Bg-Scrolled-Top0 {
    @apply bg-gradient-to-r from-[#ff8000];
  }
}
