@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-Brand-Color {
    @apply text-[#ff8000];
  }

  .text-Light-Brand-Color {
    @apply text-[#ff8000d8];
  }

  .bg-gradient-Color {
    @apply bg-gradient-to-r from-[#ff8000] to-white;
  }

  .bg-Brand-Light-Color {
    @apply bg-[#ff8000d8];
  }
}
/* ff800023 */
